<template>
  <page-view :title="title" left-arrow>
    <loading-layout ref="loadingLayout" @reaty="getYhQfxx">
      <div class="top">
        <div class="yh-zhxx">
          <dl>
            <dt>户名：</dt>
            <dd>{{yhJbxx.yhmc}}</dd>
          </dl>
          <dl>
            <dt>户号：</dt>
            <dd>{{yhJbxx.yhbh}}</dd>
          </dl>
          <dl>
            <dt>用电地址：</dt>
            <dd>{{yhJbxx.yhdz}}</dd>
          </dl>
          <dl>
            <dt>预付余额：</dt>
            <dd>{{yhJbxx.dqjy | currency}}</dd>
          </dl>
          <dl>
            <dt>总欠费：</dt>
            <dd :style="{display: 'inline-flex', alignItems: 'center'}">
              <span style="display: inline-block;">{{yhJbxx.dqqf | currency}}</span>
            </dd>
          </dl>
          <dl>
            <dt>合计违约金：</dt>
            <dd :style="{display: 'inline-flex', alignItems: 'center'}">
              <span style="display: inline-block;">{{yhJbxx.yswyj | currency}}</span>
            </dd>
          </dl>
        </div>
      </div>

      <div v-if="qfmxList && qfmxList.length" class="df-list">
        <card class="df-list-item" :shadow="false" v-for="(item, idx) in qfmxList" :key="idx">
          <template #header>
            <span class="df-list-item__zwyf">{{item.zwyf+''}}</span>
          </template>
          <van-cell-group>
            <van-cell :border="false" title="欠电费:" :value="item.dqqf | currency" />
            <van-cell :border="false" title="违约金:" :value="item.yswyj | currency" />
            <van-cell :border="false" title="应缴合计:" :value="(item.yswyj + item.dqqf) | currency" />
          </van-cell-group>
          <template #footer>
            <div class="card-footer--actions">
              <jf-action 
                style="width: 100%;" 
                :source-data="item" 
                :is-tyjs="yhJbxx.tyjsh == '' || yhJbxx.tyjsh == '0' ? false : true" 
                @success="refreshData"
                @refresh="refreshData">
                立即缴费
              </jf-action>
            </div>
          </template>

        </card>
      </div>

      <div v-else>
        <p style="margin-top: 64px; text-align: center; color: #888;">暂未查询到欠费</p>
      </div>
    </loading-layout>
  </page-view>
</template>

<script>
  import JfAction from './components/jf-action.vue'
  import {
    yhJbxx_fetchOneYhJbxxByYhbh
  } from '@/api/zhcx';
  import {
    counterFeesService_getWxgzhYhqfsj
  } from '@/api/dfcj';
  export default {
    name: 'Dfcj',
    components: {
      JfAction
    },
    data() {
      return {
        title: '电费查缴',
        yhbh: '',
        yhJbxx: {},
        yhQfhz: {},
        qfmxList: [],
        selectItem: {},
        dialogVisible: false,
      }
    },
    methods: {
      fetchYhJbxx() {
        this.$toast.loading('加载中...')
        yhJbxx_fetchOneYhJbxxByYhbh(this.yhbh).then(res => {
          this.yhJbxx = res.content;
        }).finally(() => {
          this.$toast.clear();
        });
      },
      getYhQfxx() {
        this.$refs['loadingLayout'].setStatus('loading');
        counterFeesService_getWxgzhYhqfsj(this.yhbh).then(res => {
          if (res.content.status == 200) {
            const {
              yhqfhz,
              yhqfmx
            } = res.content.data;
            this.yhJbxx = yhqfhz;
            this.qfmxList = yhqfmx;
            this.$refs['loadingLayout'].setStatus('success');
          } else {
            this.$refs['loadingLayout'].setStatus('error');
          }
        }).catch(err => {
          this.$refs['loadingLayout'].setStatus('error');
        })
      },
      refreshData() {
        counterFeesService_getWxgzhYhqfsj(this.yhbh).then(res => {
          if (res.content.status == 200) {
            const {
              yhqfhz,
              yhqfmx
            } = res.content.data;
            this.yhJbxx = yhqfhz;
            this.qfmxList = yhqfmx;
          }
        });
      },
      jfBtnClick(item) {
        this.selectItem = item;
        this.dialogVisible = true;
      },
      jfcgHandle() {
        this.getYhQfxx();
      }
    },
    mounted() {
      this.yhbh = this.$route.params.yhbh;
      // this.fetchYhJbxx();
      this.getYhQfxx();
    },
  }
</script>

<style lang="scss" scoped>
  $top-bg-color: #00b1ff;

  .top {
    flex-basis: 152px;
    background-color: $top-bg-color;
    position: relative;
  }

  .top::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/images/home/city-shadow.png');
    background-position: bottom;
    background-size: auto 90%;
    background-repeat: no-repeat;
    opacity: 0.13;
  }

  .yh-zhxx {
    position: relative;
    z-index: 1;
    padding: 20px 40px;
    color: #fff;
    font-size: 14px;
    background: rgba(0, 177, 255, 0.68);

    dl {
      margin: 0;
      line-height: 26px;
    }

    dt,
    dd {
      display: inline-block;
    }

    dd {
      margin-left: 0;
    }
  }

  .df-list-item /deep/ {
    margin: 10px;

    overflow: hidden;
    background-color: #fff;

    .van-cell {
      padding: 5px 16px;
    }
  }

  .df-list-item__zwyf {
    position: relative;
    display: inline-block;
    padding: 2px 12px;
    font-size: 14px;

    &::before {
      position: absolute;
      content: "";
      top: 4px;
      bottom: 4px;
      left: 0;
      width: 3px;
      background-color: $top-bg-color;
    }
  }
</style>