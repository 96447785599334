import fetch from '@/utils/fetch';

//电费账务psd-ms-dfzw：schema【有token的post请求方法，摘自原生F:\project\psd-ms-dfzw-ui\generated\apis\psdmsdfzw\sfglschema\ZwLjfysmxAPI.js】----------------------------------------------------------------
/* ---
根据用户编号查询电费账单
*/
export function zwLjfysmx_getDfzdByYhbh(yhbh, pagination, meta) {
    return fetch({
        url : 'psdmsdfzw/sfglschema/ZwLjfysmx/getDfzdByYhbh',
        method : 'post',
        data : {
            param : {
                yhbh : yhbh //String
            },
            pagination : pagination
        }
    })
}

//电费账务psd-ms-dfzw：service【有token的post请求方法，摘自原生F:\project\psd-ms-dfzw-ui\generated\apis\psdmsdfzw\service\CounterFeesServiceAPI.js】----------------------------------------------------------------
/* ---
查询微信公众号用户欠费明细
*/
export function counterFeesService_getWxgzhYhqfList(yhbh, meta) {
    return fetch({
        url : 'psdmsdfzw/service/CounterFeesService/getWxgzhYhqfList',
        method : 'post',
        data : {
            param : {
                yhbh : yhbh //String
            }
        }
    })
}

/* ---
查询微信公众号用户欠费数据
*/
export function counterFeesService_getWxgzhYhqfsj(yhbh, meta) {
    return fetch({
        url : 'psdmsdfzw/service/CounterFeesService/getWxgzhYhqfsj',
        method : 'post',
        data : {
            param : {
                yhbh : yhbh //String
            }
        }
    })
}

/* ---
用户欠费交费
*/
export function counterFeesService_getUserPayArrears(qfmxList,payArrears,pjxx, meta) {
    return fetch({
        url : 'psdmsdfzw/service/CounterFeesService/getUserPayArrears',
        method : 'post',
        data : {
            param : {
                qfmxList : qfmxList, //List<UserZwQfmxBean>
                payArrears : payArrears, //UserGtsfParam
                pjxx : pjxx //ZwPjxx
            }
        }
    })
}

/* ---
 缴费记录查询

*/
export function zwYhzdxx_getJfjl(jgbm,yhbh, pagination, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwYhzdxx/getJfjl',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				yhbh : yhbh //String
			},
			pagination : pagination
		}
	})
}